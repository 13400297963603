.notifications-wrapper {
  font-size: (14/12)+rem;
  list-style: none;
  margin: 0;
  padding: .5em 0;
  li {
    display: flex;
    align-items: center;
    margin: 0 1.5em;
    padding: 0.8em 0;
    line-height: 1.2;
    gap: (20/14)+em;

    border-bottom: solid 1px rgba(107, 107, 107, 0.2);

    &:last-child {
      border-bottom: none;
    }

    > .body-and-date {
      display: flex;
      gap: (20/14)+em;
      align-items: center;
      flex: 1;

      .date {
        font-size: 1rem;
      }

      @container (max-width: 40em) {
        flex-direction: column;
        gap: 0;
        align-items: start;
        .date {
          opacity: .6;
          &:before {
            content: '@';
          }
        }
      }
    }

    .body {
      flex: 1;
      a {
        //white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        font-size: inherit;
        line-height: inherit;
        vertical-align: middle;
        color: #358EE8;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .notification_closer {
      opacity: .3;
      margin: -(10/14)+em;
      padding: (10/14)+em;
      cursor: pointer;
      transition: all .3s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
}
