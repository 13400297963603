.modal-popup {
  padding: (30/12)+em 0;
  --modal-border-radius: 9px;
  border-radius: var(--modal-border-radius);
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(116, 116, 116, 0.25) 0 3px 10px;
  background: white;

  .content {
    margin: (20/12)+em 0 0 0;
  }

  .titleWithIcon {
    margin: 0 (30/12)+em;
  }
  >.footer {
    display: flex;
    .button {
      text-align: center;
      background: #22252B;
      font-size: (13/12)+em;
      font-weight: 500;
      color: white;
      padding: (10/12)+em (44/12)+em;
      border-radius: 10em;
      margin: 0 auto;
      transition: all .3s ease-in;
      cursor: pointer;
      &.negative {
        background: #F00;
        &:hover {
          background: #990000;
        }
      }
      &.positive {
        background: #080;
        &:hover {
          background: #004d00;
        }
      }
    }
  }
}
