.titleWithIcon {
  display: flex;
  align-items: center;
  .label {
    font-size: (22/12)+rem;
  }
  font-weight: 700;
  gap: .82rem;
  .icon {
    width: (30/12)+rem;
    img {
      display: block;
      //transform: scale((26/18*.8)); // ICON SCALED DOWN 80% TO FIT INTO 1330px WIDE SCREEN
      margin: auto;
    }
  }
}
