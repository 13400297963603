.input_box_with_button {
  display: inline-block;
  .input_box_with_button_wrapper {
    display: flex;
    gap: 1.5rem;
    padding: 0.2rem;
    align-items: center;
    background: white;
    border-radius: 3rem;
    line-height: normal;
    input {
      font-weight: 100;
      font-family: Poppins, sans-serif;
      margin: 0 0 0 1rem;
      border: none;
      outline: none;
      flex: 1;
      font-size: 1rem;
      width: 10rem;
      background: transparent;
    }
    .button {
      background: #22252B;
      color: white;
      font-weight: 500;
      font-size: 1rem;
      padding: 0.3rem .75rem;;
      border-radius: 2rem;
      transition: all .5s ease;
      cursor: pointer;
      min-height: unset;
      &:hover {
        color: white;
        background: #ff17a3;
      }
    }
  }
}
