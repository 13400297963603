.notifications-count-label {
  border: solid 1px #0004;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: .8em;
  position: relative;

  &[data-popup=visible] {
    background: white;
    @media screen and (min-width: 1024px) {
      &:after {
        content: '';
        position: absolute;
        bottom: -14px;
        border: solid 10px transparent;
        border-bottom-color: white;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.notifications-popup {
}

#main-popup-portal {
  position: absolute;
  left: calc(2rem - 10px);
  right: 2rem;
  top: 0;
  .notifications-popup {
    .box-wrapper {
      max-height: 50vh;
      max-width: max(600px, calc(100% - 40rem));
      overflow-y: auto;
    }
  }
}
