.tasks {
  .tasks-wrapper {
    font-size: 1em;
    color: #3A3E46;
    max-height: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      //gap: (10/12)+em;
      align-items: center;
      //padding: (26/12/2)+em 0 (30/12)+em;
      border-bottom: solid 1px rgba(107, 107, 107, 0.2);
      margin: 0 1.5em;
      padding: 0.5rem 0;
      line-height: 1.2;
      gap: (20/14)+em;

      .task_status {
        cursor: pointer;
        position: relative;
        //margin: (-14/14)+rem;
        //padding: (14/14)+rem;

        border: solid 1px #6b6b6b80;
        border-radius: 3em;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .on { color: #005407; }
        .off { color: #bbbbbb; }
      }


      .task_description {
        font-size: (14/12)+em;
        margin-left: 0;
        margin-right: 0;
        flex: 1;
        outline: none;
        overflow: hidden;
      }

      .reference_id {
        order: 10;
        font-size: (14/12)+em;
        line-height: 1.5em;
        margin: 0;
        padding: 0 1em;
        border-radius: 3px;
        text-decoration: none;
        background: #E1F1FE;
        color: #007CDB;
        font-weight: 700;
      }

    }
  }
}
