@import "../variables";

.dashboardEditWrapper {
  display: flex;
  flex-direction: column;
  padding: (30/12)+em !important;
  //border-radius: (10/12)+em;
  //position: absolute;
  //z-index: 11;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  //box-shadow: rgba(116, 116, 116, 0.25) 0 3px 10px;
  width: (660/12)+em;
  //background: white;
  gap: (30/12)+em;
  max-height: 90%;
  box-sizing: border-box;

  @include mediaQueryMobileOnly {
    width: 90%;
  }

  .layout-setup {
    display: flex;
    flex-direction: column;
    gap: remSize(15);
    @media screen and (max-device-width: 1239px){
      display: none;
    }
  }

  .buttons {
    display: flex;
    gap: (14/12)+em;
    .button {
      background: white;
      border-radius: 15px;
      font-size: remSize(13);
      font-weight: 500;
      min-height: (30/13)+em;
      padding: 0.5em (23/13)+em;
      box-sizing: border-box;
      gap: .7em;
      box-shadow: 5px 5px 10px #22252B18;
      text-decoration: none;
      color: inherit;
      position: relative;
      &.active {
        background: #22252B;
        color: white;
      }
    }
  }

  >.content.container {
    position: relative;
    display: grid;
    gap: (14/12)+em;
    list-style: none;
    margin: (8/12)+em -1em;
    padding: 1em;
    overflow: auto;
    @include mediaQueryMobileOnly {
      margin-left: -1em;
      margin-right: -1em;
      padding-left: 1em;
      padding-right: 1em;
      font-size: .8em;
    }
  }

  .description {
    font-size: (14/12)+rem;
  }
  >.footer {
    display: flex;
    .button {
      text-align: center;
      background: #22252B;
      font-size: (13/12)+em;
      font-weight: 500;
      color: white;
      padding: (10/12)+em (44/12)+em;
      border-radius: 10em;
      margin: 0 auto;
    }
  }
}
