@mixin mediaQueryMobileOnly {
  @media screen and (max-width: 1023px) {
    @content;
  }
}
@mixin mediaQueryDesktopOnly {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@function remSize($size) {
  @return ($size/12)+rem;
}
