@import "../../../../css/v3/variables";

#notifications-modal {
  color: black;
  display: none;
  @at-root [data-notifications-modal-visible="true"] & {
    display: block;
  }

  .box-wrapper {
    position: fixed;
    top: 10px;
    left: $menu-width;
    width: 80vw;
    min-height: 20rem;
    max-height: 80vh;
    max-width: 60rem;
    @at-root .side-menu.compact & {
      left: 7em;
    }
  }
}
