@import "../variables";

.assignmentsBrowser {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  //height: fit-content;
  align-self: flex-start;
  height: 100%;
  box-shadow: 0px 3px 10px rgba(116, 116, 116, 0.25);
  border-radius: (10/13)+em;
}

.assignmentsBrowserBody {
  border-bottom-right-radius: (10/13)+em;
  border-bottom-left-radius: (10/13)+em;
  background: white;
  overflow-y: auto;
  flex: 1;
  position: relative;
  .assignments-wrapper {
    padding: 0;
  }
}
