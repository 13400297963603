$menu-width: 230em/12;
//$menu-font-size: 12px;
$menu-font-size: 1rem;
$menu-ribbon-excess: 10px;
//$menu-padding: 25px;
$menu-padding: 2em;
$avatar-size: 44rem/12;
$section-padding: 13em/12;
$row-height: 35em/12;
$icon-size: 25em/12;

$light: 300;
$regular: 400;
$medium: 500;

@mixin mediaQueryMobileOnly {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@function remSize($size) {
  @return ($size/12)+rem;
}
