@import "../variables";
.box-wrapper {
  --box-wrapper--border-radius: 8px;
  border-radius: var(--box-wrapper--border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: #00000088 2px 2px 5px;
  position: relative;
  background: white;
  container-type: inline-size;

  &:hover {
    z-index: 5;
  }

  .button {
    border-radius: 3em;
    border: solid 1px #ffffff88;
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0 1.5em;
    min-height: (30/12)+em;
    color: white;
    text-decoration: none;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      background: #ff17a3;
    }
    .label {
      font-size: (13/12)+em;
      font-weight: 400;
      line-height: 1.2;
    }
  }

  .header {
    background: #22252B;
    height: 4em;
    padding: 1em 1.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    gap: 1.5em;
    &.has-action {
      cursor: pointer;
      &:hover {
        .title .label {
          text-decoration: underline;
        }
      }
    }
    .title {
      gap: 1.2em;
      .icon {
        width: 1.5em;
        img {
          //transform: scale((22/18));
        }
      }
      .badge {
        font-size: 1.2em;
        font-weight: 300;
        &:not(:empty):before {
          content: '(';
        }
        &:not(:empty):after {
          content: ')';
        }
      }
      .label {
        font-size: 1.2em;
        font-weight: 500;
        line-height: 1.2;
        .badge {
          font-size: 1em;
          display: inline;
        }
      }
    }
    .actions {
      @include mediaQueryMobileOnly {
        display: none;
      }
    }
  }
  .content {
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    position: relative;
    flex: 1;
  }
  .collapseTrigger {
    display: none;
  }
  @include mediaQueryMobileOnly {
    &.mobile-collapsable {
      .collapseTrigger {
        display: block;
        padding: 1.5rem;
        margin: -1.5rem;
        transition: all .5s ease;
      }
      &.expanded .collapseTrigger {
        transform: rotate(180deg);
      }
      .content {
        max-height: 0;
        transition: all .5s ease;
      }
      &.expanded .content {
        max-height: 65vh;
      }
    }
  }

}
