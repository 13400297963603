.notifications {
  .notifications_list {
    overflow-y: auto;
    flex: 1;
  }
  .content {
    flex-direction: column;
    display: flex;
    flex: 1;
  }

}
