@import "../variables";

.assignmentsBrowserHeader {
  border-top-right-radius: (10/13)+em;
  border-top-left-radius: (10/13)+em;
  padding: 1em (23/14)+em;
  background: #22252B;
  display: flex;
  gap: 1.5em 4em;
  align-items: center;
  color: white;
  justify-content: space-between;
  @include mediaQueryMobileOnly {
    flex-wrap: wrap;
  }
  .item {
    display: flex;
    align-items: center;
    gap: .3em (18/13)+em;
    @media screen and (max-width: 450px) {
      flex-wrap: wrap;
    }
    .status_checkboxes {
      display: flex;
      gap: (16/13)+em;
    }
    &.wide {
      flex: 1;
      .input_box_with_button {
        flex: 1;
      }
    }
  }

  .input_box_with_button_wrapper:has(input:focus),
  .input_box_with_button_wrapper:not(:has(input:placeholder-shown)) {
    box-shadow: #ff17a3 0 0 0 1px;
  }

  .react-select__control {
    min-height: 2rem;
    border-radius: 2rem;
    border: none;
  }
  .react-select__control--is-focused {
    box-shadow: #ff17a3 0 0 0 1px;
  }
  .react-select__indicator {
    padding: 3px;
  }
  .react-select__menu {
    color: black;
  }
  .react-select__option--is-focused {
    background: #ff17a340;
  }
  .react-select__option--is-selected {
    background: #ff17a3ff;
  }
}

