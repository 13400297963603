.assignment_status_popup {
  .row {
    display: flex;
    align-items: center;
    gap: 1em;
    border-bottom: solid 1px rgba(107, 107, 107, 0.2);
    padding: 1em (30/12)+em;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: lightblue;
    }
    &.active {
      font-weight: 700;
    }
  }

  .dot {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 1em;
  }
}
