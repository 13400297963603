@import "../variables";

.assignment-row {
  display: flex;
  align-items: center;
  //margin: 0 1.5em;
  padding: 1em 1.5em;
  cursor: pointer;
  position: relative;
  transition: all .2s ease;
  &:not(:last-child):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1.5em;
    width: calc(100% - 3em);
    border-bottom: solid 1px rgba(16, 19, 23, 0.2);
  }
  .number-and-picture-wrapper,
  .number-and-tags-wrapper
  {
    display: flex;
    align-items: center;
    @container lista_zlecen (max-width: #{(520/12)+em}) {
      flex-direction: column;
      gap: (6/12)+em;
      .number-wrapper {
        width: 100%;
        .number {
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  .number-wrapper {
    width: 6.5em;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    .number {
      background: #ff17a3;
      border-radius: 3px;
      color: white;
      font-size: (15/12)+em;
      padding: (3/13)+em .5em;
      font-weight: 500;
      line-height: 1;
      white-space: nowrap;
    }
  }
  .assignmentTypeMarker {
    font-family: 'Poppins', system-ui, sans-serif;
    font-weight: 900;
    font-size: .8em;
    transform: translateY(-1px);
    display: inline-block;
    background: #ffff;
    padding: 1px 2px;
    color: #157fce;
    border-radius: 2px;
    margin-right: .3em;
  }
  .picture {
    width: (120/12*0.8)+em;
    height: (80/12*0.8)+em;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //overflow: hidden;
    border: solid 1px #0001;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .more-images-bullets {
      position: absolute;
      bottom: -10px;
      display: flex;
      left: 0;
      width: 100%;
      justify-content: center;
      .image-bullet {
        display: block;
        width:8px;
        height:8px;
        padding: 1px ;
        box-sizing: border-box;
        &:after {
          display: block;
          content: '';
          background-color: #ffe2f3;
          width:4px;
          height:4px;
          border-radius: 50%;
          border: solid 1px #ff17a3;
        }
        &:first-child:after {
          background-color: #ff17a3;
        }
        &:hover:after, &.active:after {
          background-color: #ff17a3 !important;
        }
      }
      &:hover, &:has(.active) {
        .image-bullet:after {
          background-color: #ffe2f3;
        }
      }
    }

  }
  .tags {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    gap: 1px;
    width: 7em;
    .tag {
      display: block;
      border-radius: 3px;
      text-align: center;
      padding: 0 5px;
    }
  }
  .status {
    //width: (10/12)+em;
    //height: (10/12)+em;
    //border-radius: 5px;
    background: #808080;
    transition: all .5s ease;

    &[data-status="draft"] { background: #CECECE; color: #535353; .assignmentTypeMarker { color: #CECECE} }
    &[data-status="czeka"] { background: #FFBE00; .assignmentTypeMarker { color: #FFBE00} }
    &[data-status="w-trakcie-realizacji"] { background: #08B73E; .assignmentTypeMarker { color: #08B73E} }
    &[data-status="zrobione"] { background: #157FCE; .assignmentTypeMarker { color: #157FCE} }
    &[data-status="przetarg-przegrany"] { background: #8131B5; .assignmentTypeMarker { color: #8131B5} }
    &[data-status="wstrzymane"] { background: #D41C1C; .assignmentTypeMarker { color: #D41C1C} }
    &[data-status="anulowane"] { background: #22222C; .assignmentTypeMarker { color: #22222C} }

    &[data-status="confirmed"] { background: #ffff00; color: #22222C; .assignmentTypeMarker { color: #ffff00} }
    &[data-status="sent"] { background: #d4c900; color: #22222C; .assignmentTypeMarker { color: #d4c900} }
    &[data-status="approved"] { background: #68b162; .assignmentTypeMarker { color: #68b162} }
    &[data-status="accomplished"] { background: #008DFD; .assignmentTypeMarker { color: #008DFD} }
    &[data-status="lost"] { background: #000000; .assignmentTypeMarker { color: #000000} }
    &[data-status="canceled"] { background: #ff0000; .assignmentTypeMarker { color: #ff0000} }
    &[data-status="template"] { background: #fd00ef; .assignmentTypeMarker { color: #fd00ef} }
    transition: all ease .2s;
    &:hover {
      box-shadow: 0 0 0 100px #00000030 inset;
    }

  }

  .name {
    font-size: (14/12)+em;
    font-weight: 700;
    margin-bottom: 0.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    text-decoration: none;
    overflow-wrap: anywhere;
    &[href]:hover {
      text-decoration: underline;
    }
  }
  .badge {
    background: red;
    border-radius: 3px;
    display: inline-block;
    font-weight: 500;
    padding: 0 6px;
    color: white;
    line-height: normal;
    margin: -0.15em .5em;
    vertical-align: text-top;
    &.notifications {
      background: gray;
    }
  }
  .info {
    flex: 1;
    padding-left: 2em;
    //overflow: hidden;
    @at-root .archives_list & {
      margin-left: 1em;
    }
  }
  .owner {
  }
  .confidential-icon {
    font-size: 1.3em;
    margin-right: 0.3em;
  }
  .owners {
    min-width: 15em;
    width: 15%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-left: 2.5em;
    .label {
      display: block;
    }
  }
  .assignee {
    min-width: 15em;
    width: min(15%, 15em);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-left: 2.5em;
    .label {
      display: block;
    }
  }
  @container lista_zlecen (max-width: 80em) {
    .owners {
      display: none;
    }
    .date-created {
      display: none;
    }
  }
  @container lista_zlecen (max-width: 120em) {
    .assignee {
      display: none;
    }
  }
  .dates {
    margin-left: (30/12)+em;
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;
    font-weight: 300;
    gap: 0.2em;
    width: min(18rem, 20%);
    .deadline {
      font-weight: 700;
    }
    .obsolete {
      color: #DB2828;
      &:before {
        content: '';
        background: url('../../../../ui/icons/red-exclamation-mark.svg') 50% 50% no-repeat;
        background-size: contain;
        display: inline-block;
        height: 1.4em;
        width: 1.4em;
        margin-right: 0.5em;
        margin-top: -0.15em;
        margin-bottom: -0.25em;
      }
    }
  @container lista_zlecen (max-width: #{(625/12)+em}) {
    @at-root .assignments_list:not(.archives_list) & { display: none; }
    @at-root .assignments_list.archives_list & { width: 25%; }
  }

  }
  //info
  .name-and-status {
    margin-right: (30/12)+em;
  }
  //status
  .pin-wrapper {
    //margin-left: -0.75em
    padding: 1em 0.75em 1em 1.25em;
    margin-right: -0.75em;
    cursor: pointer;
    img {
      transition: all .5s ease;
      opacity: 0;
      &.active {
        opacity: .3;
      }
    }
    &:hover img {
      opacity: .7;
    }
  }
  &:hover, &.active {
    background: #ff17a320;
  }
}

