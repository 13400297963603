@import "../variables";

.recentAsignmentsWrapper {
  display: flex;
  flex-direction: column;
  //padding: (30/12)+em;
  //border-radius: (10/12)+em;
  //position: absolute;
  //z-index: 11;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  //box-shadow: rgba(116, 116, 116, 0.25) 0 3px 10px;
  width: 85%;
  //background: white;
  gap: (30/12)+em;
  max-height: 90%;
  box-sizing: border-box;

  @include mediaQueryMobileOnly {
    width: 90%;
  }

  >.content.container {
    position: relative;
    display: grid;
    gap: (14/12)+em;
    list-style: none;
    margin: (8/12)+em -1em;
    padding: 1em;
    overflow: auto;
    @include mediaQueryMobileOnly {
      margin-left: -1em;
      margin-right: -1em;
      padding-left: 1em;
      padding-right: 1em;
      font-size: .8em;
    }
  }

  .description {
    font-size: (14/12)+rem;
  }
}
