@import "../variables";
:root {
  --root-url: "/";
}

.Toastify {
  position: absolute;
}

#teams-planner {
  display: grid;
  overflow: auto;
  grid-template-rows: 1fr;
}

.team-planners {
  display: flex;
  flex-direction: column;
  gap: 40px;
  --planner--first-column-width: 200px;
  @media screen and (max-width: 768px) {
    --planner--first-column-width: 50px;
  }

  .box-wrapper.team {
    overflow: visible;
    background: #dbdbdb;

    @at-root .dashboard & {
      flex: 1 1;
    }

    &:hover {
      z-index: 6;
    }
    .content {
      overflow: visible;
      max-height: unset;
      border-bottom-right-radius: var(--box-wrapper--border-radius);
      border-bottom-left-radius: var(--box-wrapper--border-radius);
    }
    .header {
      position: sticky;
      top: 0;
      z-index: 5;
      border-top-left-radius: var(--box-wrapper--border-radius);
      border-top-right-radius: var(--box-wrapper--border-radius);
      .calendar-filters {
        display: flex;
        gap: 10px;
        @media screen and (max-width: 768px) {
          align-items: center;
        }
        .filter {
          display: flex;
          padding: 5px;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
        }
      }

      @container (width < 768px) {
        .calendar-filters,
        .full-screen-toggle,
        .show-all-planners > .label {
          display: none !important;
        }
      }
    }
    .config {
      min-width: 4em;
      height: auto;
      align-items: center;
      background: #22252b;
      box-sizing: border-box;
      color: #fff;
      display: flex;
      gap: 1.5em;
      justify-content: space-between;
      padding: 1em 1.5rem;
      position: relative;
      z-index: 5;
    }
    .planner-header-row {
      position: sticky;
      top: calc(4rem - 5px);
      z-index: 4;
    }
    &:has(.task.dummy) {
      z-index: 7;
    }
  }

  .box-wrapper .header {
    .actions {
      display: flex;
      gap: 10px;
    }
  }

  .box-wrapper .content {
    background: #dbdbdb;
  }

  .planner-header-row {
    display: grid;
    background: white;
    height: 4rem;
    border-bottom: solid 1px #0008;
    position: relative;
    font-size: 1.2em;
    margin: 5px;
    border-radius: 6px;
    overflow: hidden;

    .planner-month, .planner-day {
      gap: 0 .3em;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
    .planner-month {
      min-width: var(--planner--first-column-width, 200px);
      background: black;
      color: white;
      padding-left: 4rem;
      box-sizing: border-box;
      @media screen and (max-width: 768px) {
        padding-left: 0;
        .month-name { display: none }
      }
      @media screen and (min-width: 769px) {
        .month-number { display: none }
      }
    }
    .planner-day {
      text-align: center;
      &.weekend {
        background: #f5c7be;
      }
      &.global-event {
        background: #f5c7be;
      }
      &.today {
        background: #316522;
        color: white;
      }
    }
    &[data-mode='month'] {
      .planner-day {
        flex-direction: column;
      }
      .planner-day .day {
        font-weight: 100;
        font-size: .7rem;
      }
      .prev, .next {
        opacity: 0;
      }
      &:hover {
        .prev, .next {
          opacity: 1;
        }
      }
    }
    .prev, .next {
      position: absolute;
      height: 100%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 3px;
      transition: .3s;
      opacity: 0.5;
      @media screen and (max-width: 768px) {
        opacity: 0;
      }
      &:hover {
        background: #FF17A3;
      }
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
    &:hover {
      .prev, .next {
        opacity: 1;
      }
    }
  }
  .planner-row {
    display: grid;
    margin: 5px;
    border-radius: 6px;
    background: white;
    box-shadow: 1px 1px 2px #0004;
    transition: all .3s ease;
    //&:not(:last-child) {
    //  border-bottom: solid 1px #0004;
    //}
    &:hover {
      box-shadow: 1px 1px 3px rgba(0, 0, 0, .8);
      .planner-member {
        box-shadow: -1px 0 1px 0px #0003 inset;
      }
    }
    &.unassigned-tasks {
      //grid-template-columns: var(--planner--first-column-width, 200px) repeat(5, minmax(0, 1fr));
      position: sticky;
      top: calc(8rem - 5px);
      z-index: 3;
      @media screen and (max-width: 768px) {
        margin-top: 2em;
      }
      .planner-member {
        background-color: #C3E085;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        box-shadow: 1px 0 20px 1px #0001 inset;
        .name {
          margin-left: 10px;
          color: #036703;
          @media screen and (max-width: 768px) {
            padding: 0;
            white-space: nowrap;
            margin: -4em 0 0;
          }
        }
      }
    }
    .planner-member {
      min-width: var(--planner--first-column-width, 200px);
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 5px;
      box-sizing: border-box;
      box-shadow: -1px 0 1px 0px #0001 inset;
      .avatar {
        width: 3.6666666667rem;
        height: 3.6666666667rem;
        object-fit: cover !important;
        border-radius: 50%;
      }
      .name {
        font-weight: bold;
      }
    }
    &.personal-planner {
      .planner-member .name {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    .planner-cell {
      padding-bottom: 2em;
      position: relative;
      &.weekend {
        background: #f5c7be;
      }
      &.global-event {
        background: #f5c7be;
      }
      &.today {
        background: rgba(49, 101, 34, 0.07);
      }
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.1333333333) 0 0 10px inset;
      }
      > .task {
        --task-margin: 5px;
        --task-margin-left: var(--task-margin);
        --task-margin-right: var(--task-margin);
        --task-gap: 2px;
        height: 1.6em;
        box-sizing: border-box;
        padding: 0 var(--task-margin);
        margin: var(--task-gap) var(--task-margin-right) var(--task-gap) var(--task-margin-left);
        display: flex;
        gap: 0.4em;
        &.squashed {
          height: 2px;
          border: none !important;
          pointer-events: none;
          > * { display: none };
        }
        > p {
          max-width: calc(100% - 2*var(--task-margin));
          //flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
          margin: 0;
          pointer-events: none;
          font-weight: 400;
          .emoji {
            top: -.1em;
            vertical-align: top;
            position: relative;
          }
        }
        .ownerAcronym {
          font-style: normal;
          font-weight: 500;
          background: #0004;
          color: white;
          padding: 0 4px;
          border-radius: 3px;
          font-size: 9px;
          line-height: 16px;
        }
        .otherTeam {
          font-style: normal;
          //font-weight: bold;
          //background: #0002;
          //color: white;
          //padding: 0 4px;
          //border-radius: 3px;
          &:before {
            content: '';
            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik03LjUzIDYuNTNhLjc1Ljc1IDAgMCAwLTEuMDYtMS4wNmwtMiAyYS43NS43NSAwIDAgMCAwIDEuMDZsMiAyYS43NS43NSAwIDEgMCAxLjA2LTEuMDZsLS43Mi0uNzJIMTdhLjc1Ljc1IDAgMCAwIDAtMS41SDYuODF6bTguOTQgNi45NGEuNzUuNzUgMCAwIDAgMCAxLjA2bC43Mi43Mkg3YS43NS43NSAwIDAgMCAwIDEuNWgxMC4xOWwtLjcyLjcyYS43NS43NSAwIDEgMCAxLjA2IDEuMDZsMi0yYS43NS43NSAwIDAgMCAwLTEuMDZsLTItMmEuNzUuNzUgMCAwIDAtMS4wNiAwIi8+PC9zdmc+Cg==") 50% 50% no-repeat;
            background-size: contain;
            height: 1.4em;
            width: 1.2em;
            display: inline-block;
          }

          & ~ p {
            opacity: .6;
            font-weight: 100;
          }
        }
        .designLink,
        .publishingLink {
          pointer-events: all;
          border-radius: 3px;
          background: #515E9C;
          color: white;
          text-decoration: none;
          padding: 1px 3px;
        }
        @at-root .team-planners .team:has([data-mode="month"]) .planner-row .planner-cell > .task {
          --task-margin: 1px;
        }
        &.temporary {
          pointer-events: none;
          opacity: .5;
        }
        &.frozen {
          opacity: .5;
          pointer-events: none;
        }
        &.spacer {
          visibility: hidden;
          pointer-events: none;
        }
        &.dummy {
          position: fixed !important;
          z-index: 10 !important;
          pointer-events: none; // don't listen to mouseUp on dummy element
          &.resize {
            position: absolute !important;
          }
        }
        &.first {
          border: solid 1px #0004;
          border-radius: 5px;
          position: relative;
          z-index: 1;
          &.continued {
            --task-margin-left: 0px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-left: 0;
          }
          &.continues {
            --task-margin-right: 0px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: 0;
          }
          &:hover {
            background: #ffff00;
            z-index: 2;
          }
        }
        &.invisible {
          visibility: hidden;
          pointer-events: none;
        }
        &.temporary {
          opacity: .5;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .taskRightHandle, .taskLeftHandle {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 10px;
          cursor: col-resize;
        }
        .taskRightHandle {
          right: -5px;
        }
        .taskLeftHandle {
          left: -5px;
        }
      }
    }
  }

  .team .planner-cell .tasks_popup {
    position: absolute;
    z-index: 10;
    background: white;
    border: solid 1px #808080;
    border-radius: 3px;
    top: 1.6em;
    display: none;
    box-shadow: 2px 2px 5px rgba(0,0,0,.533);
    .tasks_from_system {
      padding-left: calc( 8px + 1.5em);
      text-indent: -1.5em;
      padding-right: 8px;
      padding-top: .75em;
      padding-bottom: .75em;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.1333333333) 0 0 10px inset;
      }
      &:not(:last-child) {
        border-bottom: solid 1px #ddd;
      }
    }
  }

  .team .planner-cell .task:hover .tasks_popup {
    display: block;
  }

  .team .planner-cell .task-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0001;
  }

  & ~ #team-tooltip-light {
    background-color: #fff;
    color: black;
    opacity: 1;
  }
}
