.del_all_notification_btn {
  margin: 2em 1em 2em auto;
  display: block;
  color: red !important;
  text-align: right;
  font-size: 9px;
  align-self: flex-end;
}

.empty-notifications-message {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  padding: 20px;
  box-sizing: border-box;
  max-height: 100%;
  text-align: center;
}
