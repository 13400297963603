@import "../variables";

.configToggleBar {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: (10/12)+em;
  align-items: center;
  box-shadow: rgba(116, 116, 116, 0.25) 0 3px 10px;
  background: white;
  pointer-events: none;
  .checkbox {
    pointer-events: all;
    margin: (-28/12)+em;
    padding: (28/12)+em;
    width: 1.6rem;
    img {
      display: block;
      pointer-events: none; // to prevent image dragging
      max-width: 100%;
    }
  }
  .title {
    font-size: 1.3rem;
    font-weight: 600;
    flex: 1;
  }
  .handle {
    pointer-events: all;
    width: 1.7rem;
    display: flex;
    img {
      pointer-events: none; // to allow bar drag start
      max-width: 100%;
    }
  }
}
