.team-task-form {
  min-width: 450px;
  padding-top: 0;
  .person {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    padding: 2rem 2.5rem;
    border-bottom: solid 1px #0004;
    font-size: 1.8333333333rem;
    font-weight: 500;
    border-top-right-radius: var(--modal-border-radius);
    border-top-left-radius: var(--modal-border-radius);
    .avatar {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .content {
    padding: 2em 2.5em;
    margin: 0;
  }
  .form-row {
    margin: 1em 0;
    &:first-child {
      margin-top: 0;
    }
    label {
      display: block;
    }
  }
  .form-row:has(>.form-column) {
    display: flex;
    .form-column {
      flex: 1;
    }
  }
  .color-grid-wrapper {
    display: flex;
  }
  .group-name {
    border: solid 1px #0000004a;
    border-bottom: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding: 3px 10px;
    box-sizing: border-box;
  }
  .color-swatches {
    display: flex;
  }
  .color-swatch {
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .react-datepicker {
    font-size: 1rem;
  }
  .react-datepicker__day-names {
    margin-top: 5px;
  }
  input[type=text] {
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
  }
  .footer {
    border-top: 1px solid rgba(0,0,0,.267);
    padding: 2.5em;
    padding-bottom: 0;
    justify-content: space-between;
    .button {
      margin: 0;
    }
  }
}
