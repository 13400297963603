@import "../variables";
.archive-row {
  .attachments {
    width: 20em;
    margin-left: 20px;
    word-wrap: break-word;
  }
  a.quickAccessLink {
    display: block;
    background: white url(../static/images/attachment.png) 10px 50% no-repeat;
    padding: 3px 0 3px 3em;
    margin: 1px 0;
    border-radius: 3px;
    &:hover {
      background-color: rgba(#ff17a3, .3);
    }
  }
}
