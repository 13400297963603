.status_checkbox {
  display: inline;
  .status_checkbox_content {
    display: flex;
    width: 2.3em;
    height: 2.3em;;
    justify-content: center;
    align-items: center;
    border-radius: (3/13)+em;
    background: #CECECE;
    &.with_border {
      border: solid 1px #FFFFFF32;
      box-sizing: border-box;
    }
  }
  .checkbox_widget {
    display: block;
    width: (16/13)+em;
    height: (16/13)+em;;
    background: white;
    border-radius: (2/13)+em;
    svg {
      display: none;
      width: 100%;
      height: 100%;
    }
    &.checked {
      background: transparent;
      svg {
        display: block;
      }
    }
  }
}
